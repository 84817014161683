@import './variables.scss';

@mixin main {
  flex-grow: 1;
  padding: 92px 150px 60px;
  color: #464646;
}

@mixin button {
  cursor: pointer;
  text-align: center;
  border: none;
  font-family: 'PT Sans Caption', sans-serif;
  font-weight: 700;
}

@mixin primaryButton($width: 252px) {
  font-size: 20px;
  line-height: 26px;
  width: $width;
  padding: 17px;
  border-radius: 30px;
  background-color: $basic-dark-color;
  color: #ffffff;

  &:hover {
    background-color: $button-hover-color;
  }

  &:active {
    background-color: $button-active-color;
  }

  &[disabled] {
    background-color: $button-disabled-color;
  }
}

@mixin uploadFileButton {
  @include button;
  @include primaryButton();
}

@mixin tip {
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  align-self: center;
}
