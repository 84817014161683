@import '../../sass/mixins.scss';
@import '../../sass/animations.scss';

.loader {
  @include tip;
  position: relative;

  &::after {
    content: '';
    width: 21px;
    height: 21px;
    background-color: transparent;
    border: 2px solid currentColor;
    border-top: 5px solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 0;
    margin-left: 10px;
  }
}
