// colors

$basic-color: #e6e6e6;
$basic-dark-color: #7f548d;
$link-hover-color: #db9fef;
$link-active-color: #5f2b70;
$background-color: #c8c8c8;
$button-hover-color: #a66bb9;
$button-active-color: #a041c1;
$button-disabled-color: #a18fa7;
$field-bg-color: #e9e9e9;
$error-color: #ff0f0f;

// app
$app-width: 1440px;
