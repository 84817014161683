@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.recognition__wrap {
  display: flex;
  justify-content: space-between;
}

.recognition__submit {
  @include button;
  @include primaryButton();
}

.recognition__container {
  padding-top: 61px;
  position: relative;
  margin-bottom: 25px;
}

.recognition__invalid {
  color: $error-color;
}

.recognition__invalid,
.recognition__loader {
  @include tip;
  position: absolute;
  top: 20px;
  left: 0;
  margin-left: 10px;
}

.recognition__result {
  width: 100%;
  min-height: 247px;
  background-color: $field-bg-color;
  border-radius: 12px;
  padding: 12px 28px 17px 20px;
  font-size: 20px;
  line-height: 26px;
}

.recognition__download {
  @include button;
  @include primaryButton();
  text-decoration: none;
  display: block;

  &--disabled {
    cursor: default;
    pointer-events: none;
    background-color: $button-disabled-color;
  }
}
