@import '../../sass/variables.scss';

.textarea-container {
  position: relative;
  grid-area: 2/1/3/-1;
  height: 300px;
}

.textarea {
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  border-radius: 12px;
  font-family: 'PT Sans Caption', sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  padding: 12px 20px;
  background-color: $field-bg-color;

  &[disabled] {
    opacity: 0.9;
  }
}

.label-placeholder {
  font-family: 'PT Sans Caption', sans-serif;
  font-size: 20px;
  line-height: 26px;
  background-color: transparent;
  position: absolute;
  top: 12px;
  left: 20px;
  pointer-events: none;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.textarea:focus + .label-placeholder,
.textarea:active + .label-placeholder,
.label-placeholder--offset {
  font-size: 16px;
  line-height: 20px;
  transform: translateY(-30px);
  max-width: 200px;
}

.textarea[disabled] + .label-placeholder {
  opacity: 0.9;
}

.textarea__clear {
  background-color: transparent;
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-image: url('../../images/clear-button.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 50%;
  position: absolute;
  right: 26px;
  top: 12px;

  &:hover {
    opacity: 60%;
  }

  &:active {
    opacity: 30%;
  }
}
