@import '../../sass/mixins.scss';

.load-text-label {
  @include uploadFileButton;
}

input[type='file'][disabled] ~ label {
  background-color: $button-disabled-color;
}

input[type='file']:focus ~ label {
  outline: auto;
}
