@import '../../sass/mixins.scss';
@import '../../sass/variables.scss';

.main {
  @include main();
}

.main__container {
  width: 655px;
  margin: 0 auto;
}

.tab-content {
  padding: 32px 30px;
  background-color: $background-color;
  border-radius: 12px;
  border-top-left-radius: 0;
}
