@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/ptsans-caption-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/ptsans-caption-bold.woff2') format('woff2');
}

@font-face {
  font-family: 'PT Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/ptmono-bold.woff2') format('woff2');
}
